<template>
  <div class="wrap">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input
            class="fromInp"
            v-model="manageForm.LegalPersonlPhone"
            placeholder="输入手机号"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activePage" @tab-click="handleClick">
        <el-tab-pane label="待设置代理商列表" name="basicInfo">
          <el-table
            :data="tableData"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="loading">
            <el-table-column type="index" align="center" label="序号" width="50" fixed show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" fixed show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonIName" label="姓名" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Status" label="审核状态" width="90" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="success">{{ scope.row.Status }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="ConfigStatus" label="设置状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="warning">{{ scope.row.ConfigStatus }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="Remark" label="驳回原因" width="300" fixed="right">
              <template slot-scope="scope">
                <el-alert :title="scope.row.Remark" type="warning" :closable="false"></el-alert>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyRemark" label="备注" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="setClick(scope.row)" icon="el-icon-setting">设置</el-button>
                <el-button type="primary" size="small" @click="goFacility(scope.row,pagination.page)" icon="el-icon-document">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px">
            <!-- 分页 -->
            <el-pagination background
              class="pagination"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.page"
              :page-size="pagination.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已设置代理商列表" name="authInfo">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
            <el-table-column type="index" align="center" label="序号" width="50" fixed show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" fixed show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="220" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonIName" label="姓名" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Status" label="审核状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="success">{{ scope.row.Status }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="ConfigStatus" label="设置状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="success">{{ scope.row.ConfigStatus }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyRemark" label="备注" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="操作" width="140" fixed="right" show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- <el-button type="primary" size="small" disabled @click="goFacility(scope.row)" icon="el-icon-setting">设置</el-button> -->
                <el-button type="primary" size="small" @click="goFacility(scope.row,pagination.page)" icon="el-icon-document">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px">
            <!-- 分页 -->
            <el-pagination background
              class="pagination"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.page"
              :page-size="pagination.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" class="deep_dialog">
      <span slot="title">设置分成比例</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>当前角色：</strong><u>代理商</u><br />
        </div>
        <div class="dialogInfo">
          <div>
            <strong>注册ID：</strong><u>{{tenant.LegalPersonlPhone}} </u><br />
            <strong>企业名称：</strong><u> {{tenant.EnterpriseFullName}}</u><br />
            <strong>身份证号码：</strong><u> {{tenant.LegalPersonIIDCard}}</u><br />
          </div>
          <div>
            <strong>姓名： </strong><u>{{tenant.LegalPersonIName}} </u><br /> 
            <strong>电话：</strong><u>{{tenant.LegalPersonlPhone}} </u><br />
            <strong>信用代码：</strong><u> {{tenant.EnterpriseCreditCode}}</u><br />
          </div>
        </div>
        <el-table
          :data="tenant.eInvoiceTypeList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          v-loading="loading"
          max-height="300px">
          <el-table-column align="center" prop="EnterpriseAddress" label="发票-业务-场景" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.TaskSceneName">{{scope.row.InvoiceTypeName}} / {{scope.row.TaskTypeName}} / {{scope.row.TaskSceneName}}</div>
              <div v-else>{{scope.row.InvoiceTypeName}}</div>
            </template>
          </el-table-column>
          <el-table-column align="center"  label="平台-服务商结算费率" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                class="fromInp"
                v-model="scope.row.Rate"
                placeholder="输入服务商结算费率（固定扣点）"
                maxlength="5"
                onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                oninput="if(value > 99 || value < 0 ){value = ''}"
                @input="nextClick(scope.row,$event)">
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-alert style="margin-top:10px;" type="warning" :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br/>
          <span>
            1.请客服查阅并保存平台、代理商之间协议；<br/>
            2.确定平台、代理商之间的各类型发票结算费率；<br/>
            3.当前费率仅用于保存记录，实际计算线下处理；<br/>
          </span>
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateCommission(tenant.eInvoiceTypeList)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import verify from "@/utils/verify";
import { _convertCurrency } from "@/utils/utils";
import {
  ByAgentList,
  UpdateCommission,
  GetPlatFormByAgentInvoice
} from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      // 费率集合
      commission: {
        RateMinimum: "",
        Rate: "",
        TotalInvoiceAmount: "",
      },
      // 分成模式弹窗
      dialogVisible: false,
      // 选中的分成模式
      dialogName: "first",
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 数据列博鳌
      tableData: [],
      //当前激活哪个tabs页
      activePage: "basicInfo",
      // 设置状态
      ConfigStatus: 0,
      // 当前被查看数据的ID
      CustomerID: "",
      CommissionType: "01",
      // 单条数据信息
      tenant: "",
      loading: true,
    };
  },
  created() {
    if(this.$route.query.pageIndex){
      this.pagination.page = Number(this.$route.query.pageIndex)
    }
    //刷新页面时确定tabs的选中
    this.chooseTabsIndex();
    // 数据列表
    this.ByAgentList();
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed () {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    searchChange(){
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler (e) {
      if(this.$route.path == '/auditInfo/setAgency/index'){
        // 重置路由
        this.$router.push({
          path: "/auditInfo/setAgency/index"
        });
      }
    },
    // 设置发票费率
    nextClick(data,e){
      if(Number(e) >= Number(data.DefaultRate)){
        this.$confirm('建议：服务商的结算费率应小于发票费率。如您坚持设置此数值，请与各相关方协商好。谢谢~', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
        }).catch(() => {
          
        })
      }
    },
    // 设置分成比例
    UpdateCommission(rateData) {
      let data = {
        CustomerID: this.CustomerID,
        Rate:rateData[0].Rate,
        configList:[],
        TotalInvoiceAmount: this.commission.TotalInvoiceAmount,
      };
      for(let i=0; i<rateData.length;i++){
        if(rateData[i].TaskSceneTypeID){
          let params = {
            TaskSceneTypeID:rateData[i].TaskSceneTypeID,
            RateMinimun:verify._radix(rateData[i].Rate)
          }
          data.configList.push(params)
        }
      }
      UpdateCommission({ Json: JSON.stringify(data) }).then((res) => {
        this.loading = true;
        let data = {
          EnterpriseFullName: this.manageForm.EnterpriseFullName,
          LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
          SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
          SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
          pageSize: this.pagination.pagesize,
          pageIndex: 1,
          ConfigStatus: this.ConfigStatus,
        };
        this.BySupplierLists(data);
        setTimeout(() => {
          this.dialogVisible = false;
        });
      });
    },
    // 显示分成比例弹窗
    setClick(item) {
      this.CustomerID = item.UserID;
      this.tenant = item;
      GetPlatFormByAgentInvoice({UserID:item.UserID}).then(res=>{
        let ratelist = [
          // {
          //   InvoiceTypeName:'平台技术服务费费率',
          //   Rate:res.eInvoiceTypeList[0].Rate?res.eInvoiceTypeList[0].Rate:'0.30'
          // }
        ]
        res.eInvoiceTypeList.forEach(item => {
          item.Rate = item.RateMinimun?item.RateMinimun:''
          ratelist.push(item)
        })
        this.commission.TotalInvoiceAmount = res.eInvoiceTypeList[0].TotalInvoiceAmount
        this.tenant.eInvoiceTypeList = ratelist
        this.dialogVisible = true;
      })
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // 搜索
    search() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // tab栏点击事件
    handleClick(tab) {
      this.loading = true;
      this.pagination.page = 1;
      this.ConfigStatus = Number(tab.index);
      this.ByAgentList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    //刷新页面时确定tabs的选中
    chooseTabsIndex() {
      if (this.$route.fullPath.indexOf("basicInfo") != -1) {
        this.activePage = "basicInfo"
        this.ConfigStatus = 0
      } else if (this.$route.fullPath.indexOf("authInfo") != -1) {
        this.activePage = "authInfo"
        this.ConfigStatus = 1
      }
    },
    // 设置服务商分成比例列表
    ByAgentList() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // 设置服务商分成比例列表
    BySupplierLists(data) {
      ByAgentList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 详情跳转
    goFacility(item,pageIndex) {
      this.$router.push({
        path: "/auditInfo/setAgency/agencyInfo",
        query: { 
          UserID: item.UserID,
          urlType: this.activePage,
          pageIndex
        },
      });
    },
  },
};
</script>

<style lang="scss">
.wrap{
  .el-input.is-disabled .el-input__inner {
    color: #333;
  }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
}
.mdoeDiv {
  width: 80%;
  margin: 10px 0 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10%;
  color: #333;
  span {
    color: red;
    font-size: 16px;
  }
}
.dialog_box{
  font-size:14px;
  line-height: 26px;
  width:100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;
  .dialogRole{
    width: 100%;
    text-align:center;
  }
  .dialogInfo{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
.deep_dialog{
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>